





































































































































import { Component, Vue } from 'vue-property-decorator';
import vueDebounce from 'vue-debounce';
import { ExerciseModel, QpointDocument, QpointModel } from '@/models';
import BrandStore from '@/store/modules/brand';
import { Helper, CSV } from '@/util';
import { saveAs } from 'file-saver';

// This should be a global setting, not a view setting
Vue.use(vueDebounce, {
  defaultTime: '250ms',
});

interface QpointExerciseMapEntry {
  id: string;
  code: string;
}

type QpointExerciseMap = Record<string, QpointExerciseMapEntry[]>;

@Component({
  name: 'QpointList',
})
export default class extends Vue {
  private isSearching: boolean = false;
  private qpoints: QpointDocument[] = [];
  private exerciseMap: QpointExerciseMap = (undefined as unknown) as QpointExerciseMap;
  private filteredQpoints: QpointDocument[] = [];
  private qpointsSlice: QpointDocument[] = [];
  private qpointsSliceSize: number = 100;
  private totalResults: number = 0;
  private page: number = 0;
  private editableQpoint: any = {} as any;
  private editableIndex: number = -1;
  private isAddMode: boolean = false;
  private isUpdateMode: boolean = false;
  private qpointFilter: string = '';

  // Table Search Data
  // =================
  private async doSearch(): Promise<void> {
    if (this.isSearching) {
      return;
    }

    this.isSearching = true;

    const [qpoints, exerciseMap] = await Promise.all([
      QpointModel.find({}),
      (await ExerciseModel.find({})).reduce((accumulator: QpointExerciseMap, exercise) => {
        exercise.qpoints.forEach(code => {
          accumulator[code] = [...(accumulator[code] || []), { id: exercise.id, code: exercise.code }];
        });

        return accumulator;
      }, {} as QpointExerciseMap),
    ]);

    this.qpoints = qpoints;
    this.exerciseMap = exerciseMap;

    this.filterQpoints();
    this.isSearching = false;
  }

  // WTF!? How about fix the data and not code around it!
  private filterQpoints() {
    const qpointFilter = this.qpointFilter;
    if (!qpointFilter || qpointFilter.trim().length <= 2) {
      this.filteredQpoints = this.qpoints;
      this.filteredQpoints.sort(this.sortCode);
      this.totalResults = this.filteredQpoints.length;
      this.setPagination(1);
      return;
    }
    this.filteredQpoints = this.qpoints.filter(this.filterQpoint);
    this.totalResults = this.filteredQpoints.length;
    this.setPagination(1);
  }

  private getMappedExercises(code: string): QpointExerciseMapEntry[] {
    return this.exerciseMap[code] || [];
  }

  private tableData() {
    const results = this.isAddMode ? [this.editableQpoint].concat(this.qpointsSlice) : this.qpointsSlice;
    return results;
  }

  private filterQpoint(qpoint: QpointDocument) {
    const CODE_PREFIX = 'QP';
    const CODE_REGEX = new RegExp(`^${CODE_PREFIX}\\d+$`, 'i');
    let filter = this.qpointFilter;
    const isCode = CODE_REGEX.test(filter);
    if (isCode) {
      const codeOrdinalNum: string = filter.substr(CODE_PREFIX.length);
      filter = `${CODE_PREFIX}${parseInt(codeOrdinalNum)}`;
    }
    return qpoint.code.toLowerCase() === filter.toLowerCase() || qpoint.label.toLowerCase().indexOf(filter.toLowerCase()) > -1;
  }

  private clearFilterQpoints() {
    if (!this.qpointFilter) {
      this.filteredQpoints = this.qpoints;
      this.totalResults = this.filteredQpoints.length;
      this.setPagination(1);
    }
  }

  private gotoExercise(exerciseId: string): void {
    window.open(Helper.routeHref({ name: 'exercise-update', params: { id: exerciseId } }), '_blank');
  }

  // Page Logic
  //===========

  private setPagination(newPage: number): void {
    const startPos: number = Math.max(0, this.qpointsSliceSize * (newPage - 1));
    this.qpointsSlice = this.filteredQpoints.slice(startPos, startPos + this.qpointsSliceSize);
    this.qpointsSlice.sort(this.sortCode);
    this.page = newPage;
  }

  private rowFrom() {
    return (this.page - 1) * this.qpointsSliceSize + 1;
  }

  private rowTo() {
    return Math.min(this.page * this.qpointsSliceSize, this.totalResults);
  }

  // Editable Logic
  //===============
  private handleDblClickRow(row: QpointDocument) {
    const editableIndex = this.tableData().findIndex(qpoint => qpoint.id === row.id);
    this.setEditMode(row, editableIndex);
  }

  private setAddMode() {
    this.editableQpoint = {
      code: ' ',
      audio: '',
      label: '',
    };
    this.isAddMode = true;
    this.isUpdateMode = false;
    this.editableIndex = 0;
    const refs: any = this.$refs;
    setTimeout(() => this.$nextTick(() => refs.label.focus()), 100);
  }

  private setEditMode(selectedQpoint: QpointDocument, editableIndex: number) {
    const { id, code, label, audio } = selectedQpoint;
    this.editableQpoint = { id, code, label, audio };
    this.editableIndex = this.isAddMode ? editableIndex - 1 : editableIndex;
    this.isUpdateMode = true;
    this.isAddMode = false;
    const refs: any = this.$refs;
    setTimeout(() => this.$nextTick(() => refs.label.focus()), 100);
  }

  private isEditable(index: number, row: QpointDocument) {
    if (this.isAddMode && index === 0) {
      return true;
    }
    if (this.isUpdateMode && this.editableQpoint.id === row.id) {
      return true;
    }
    return false;
  }

  private isEditMode() {
    return this.isAddMode || this.isUpdateMode;
  }

  private canSave() {
    return this.editableQpoint.audio !== null && this.editableQpoint.audio !== '' && this.editableQpoint.label !== null && this.editableQpoint.label !== '';
  }

  private async handleCancelSave() {
    this.isAddMode = false;
    this.isUpdateMode = false;
  }

  private async handleSaveRow(row: QpointDocument) {
    try {
      if (!this.canSave()) {
        return;
      }
      const isNew = this.editableQpoint.id ? false : true;
      const qpoint = QpointModel.create(this.editableQpoint, isNew);

      await qpoint.validate();

      await qpoint.save();
      row = { ...this.editableQpoint };
      const action = isNew ? 'created' : 'updated';
      this.$message({
        message: `QPoint successfully ${action}.`,
        type: 'success',
      });
      const afterSavePage = this.isAddMode ? 1 : this.page;
      this.isAddMode = false;
      await this.doSearch();
      this.setPagination(afterSavePage);
      this.isUpdateMode = false;
    } catch (e) {
      console.error('ERROR', e);
    }
  }

  private async handleDeleteRow(qpoint: QpointDocument) {
    try {
      await qpoint.remove();
      this.$message({
        message: `QPoint ${qpoint.code} successfully deleted.`,
        type: 'success',
      });
      const afterSavePage = this.page;
      await this.doSearch();
      this.setPagination(afterSavePage);
    } catch (e) {
      this.$alert((e as any).message, `QPoint deletion failed`, { type: 'error' });
    }
  }

  // Sort Logic
  //===========

  public sortCode(qp1: QpointDocument, qp2: QpointDocument) {
    const code1 = qp1.code || '0';
    const code2 = qp2.code || '0';
    const val1 = `0${code1.replace(/\D/g, '')}`;
    const val2 = `0${code2.replace(/\D/g, '')}`;
    return parseInt(val2) - parseInt(val1);
  }

  public mounted() {
    this.doSearch();
  }

  // Download CSV
  //=============
  private async downloadCSV(): Promise<void> {
    const columnMap: Record<string, string> = BrandStore.list.reduce((accumulator, brand) => ({ ...accumulator, [brand.id]: brand.name }), {
      ordinalCode: 'Ordinal',
      code: 'Code',
      audio: 'Long text for audio',
      label: 'Short text for graphics',
    });

    const qpointBrandMap: Record<string, string[]> = (await ExerciseModel.find()).reduce((accumulator, exercise) => {
      const brandsInUse: string[] = exercise.brandSetup.reduce((brandAccumulator, brandSetup) => [...brandAccumulator, brandSetup.brandId], [] as string[]);

      if (brandsInUse.length > 0) {
        exercise.qpoints.forEach(qpoint => {
          if (!Array.isArray(accumulator[qpoint])) {
            accumulator[qpoint] = [];
          }

          accumulator[qpoint] = [...accumulator[qpoint], ...brandsInUse];
        });
      }

      return accumulator;
    }, {} as Record<string, string[]>);

    const results = this.filteredQpoints.map(qpoint => ({
      ordinalCode: qpoint.ordinalCode,
      code: qpoint.code,
      audio: qpoint.audio,
      label: qpoint.label,
      ...BrandStore.list.reduce(
        (accumulator, brand) => ({ ...accumulator, [brand.id]: qpointBrandMap[qpoint.code] && qpointBrandMap[qpoint.code].includes(brand.id) ? 'Y' : '' }),
        {} as Record<string, string>,
      ),
    }));

    const csvStr: string = CSV.fromArray(results, columnMap);

    saveAs('data:application/csv;charset=utf-8,' + encodeURIComponent(csvStr), 'qpoint-list.csv');
  }
}
